<template>
    <user-link
        v-if="clusterNode.people.length === 1"
        v-bind="clusterNode.people[0]"
        @click.native="$emit('avatar-click')"
    >
        <avatar
            v-bind="clusterNode.people[0]"
            class="depth-3"
        >
        </avatar>
    </user-link>
    <avatar
        v-else
        v-tooltip.bottom="tooltipMessage"
        :class="cssClasses"
        @click.native="$emit('cluster-click')"
    >
        {{ clusterNode.people.length }}
    </avatar>
</template>

<script>

import UserLink from "@web/components/user/UserLink";
import Avatar from "@web/components/Avatar";

export default {
    name: "PeopleMapMarker",
    components: { Avatar, UserLink },
    props: {
        /**
         * {
         *     id: string,
         *     people: [{
         *         uid: string,
         *         location: {
         *             name: string,
         *             detail: string,
         *             lat: number,
         *             lng: number
         *         },
         *         photoURL?: string,
         *         displayName: string,
         *     }],
         *     position: {
         *         lat: number,
         *         lng: number,
         *     },
         *     everybodyAtSameLocation?: bool,
         * }
         */
        clusterNode: { type: Object, required: true },
    },
    computed: {
        cssClasses() {
            const generalStyle = "depth-3 cluster-icon cursor-pointer";
            const clusterTypeSpecificStyle = this.clusterNode.everybodyAtSameLocation ? "background-primary text-primary-text" : "background-secondary text-secondary-text";
            return `${clusterTypeSpecificStyle} ${generalStyle}`;
        },
        tooltipMessage() {
            return this.clusterNode.everybodyAtSameLocation ? this.$t("team_profile_map_tooltip_user_list") : this.$t("team_profile_map_tooltip_zoom");
        },
    },
};
</script>

<style scoped>

</style>
