<template>
    <editable-team-section
        class="transition-all top-intro"
        :hide-title="true"
        button-position="top-right"
        :fields="['description']"
        :team="team"
        :editable="editable"
        :open-in-edit-mode="openInEditMode"
        @update:editing="$emit('update:editing', $event)"
        @section-updated="$emit('section-updated', $event)"
    >
        <template #viewing>
            <p
                v-if="(!team.description || isDescriptionEmpty) && editable"
                class="text-medium-contrast"
            >
                {{ $t('team_description_placeholder') }}
            </p>
            <RichTextEditor
                v-if="team.description && !isDescriptionEmpty"
                :value="team.description"
                :is-empty.sync="isDescriptionEmpty"
                truncate
            />
        </template>
        <template #editing="{formData, isSaving}">
            <RichTextEditor
                v-model="formData.description"
                class="input"
                :is-empty.sync="isDescriptionEmpty"
                edit-mode
                :place-holder="$t('team_description_placeholder')"
            />
        </template>
    </editable-team-section>
</template>

<script>
import RichTextEditor from "@/components/editor/RichTextEditor";
import EditableTeamSection from "@/views/intranet/directory/teams/TeamProfile/EditableTeamSection";

export default {
    name: "TeamDescription",
    components: {
        EditableTeamSection,
        RichTextEditor
    },
    props: {
        /** @type {TeamProfile} */
        team: { type: Object, required: true },
        editable: Boolean,
        openInEditMode: Boolean,
    },
    data() {
        return {
            isDescriptionEmpty: false,
        };
    },
};
</script>

<style lang="scss" scoped>

    .top-intro {
        width: 100%;

        @media screen and (min-width: $breakpoint-regular){
            min-height: 3.5rem;
        }
    }
    .quill-editor.edit {
        margin-right: 5rem;
    }
</style>
