<template>
    <section
        v-global-page-title="group.name"
        class="container"
    >
        <header class="pbottom-large">
            <app-button
                href="#"
                type="link no-padding"
                @click="$router.go(-1)"
            >
                « {{ $t("back") }}
            </app-button>
            <h1>{{ group.name }}</h1>
            <h2>{{ group.type }}</h2>
            <p class="desc">
                {{ group.description }}
            </p>
            <div class="meta pbottom-medium">
                <strong>{{ $t("location") }}:</strong> London (87,5%) Remote (12,5%)
            </div>
            <hr/>
        </header>
        <main class="pbottom-large">
            <div class="grid">
                <div class="col half">
                    <h3>{{ $t("skills") }}</h3>
                    <span v-html="require(`@web/assets/system/bubbles.svg`)" ></span>
                </div>
                <div class="col half">
                    <h3>
                        {{ $t("members") }}
                        <span style="font-weight: normal; opacity: 0.3;">{{
                            group.members
                        }}</span>
                    </h3>

                    <section class="members">
                        <div
                            v-for="user in users"
                            :key="user.name"
                            class="user-row"
                        >
                            <div class="avatar avatar-medium">
                                <img
                                    :src="user.avatar"
                                    alt=""
                                />
                            </div>
                            <div class="text">
                                <div class="name">
                                    {{ user.name }}
                                </div>
                                <div class="meta">
                                    {{ user.meta }}
                                </div>
                            </div>
                            <div class="slot-custom" ></div>
                        </div>
                    </section>
                </div>
            </div>
        </main>
    </section>
</template>

<script>
export default {
    props: {
        groupId: {
            type: Number,
            default: 0
        },
        group: {
            type: Object,
            default: () => {
                return {
                    name: "Group",
                    description:
                            "Capicola beef ribs t-bone tri-tip landjaeger fatback, beef meatloaf salami tenderloin leberkas. Venison beef pork chop doner strip steak. Fatback turducken chuck, pig boudin buffalo meatball tri-tip ball tip shank andouille alcatra.",
                    members: 5,
                    type: "Group"
                };
            }
        }
    },
    data() {
        return {
            users: [
                {
                    name: "Colin Stark",
                    meta: "UI Designer • Team Halo",
                    avatar:
                            "https://scontent-frx5-1.cdninstagram.com/v/t51.2885-19/s320x320/16228890_594725164055676_7057958098689851392_a.jpg?_nc_ht=scontent-frx5-1.cdninstagram.com&_nc_ohc=FDvoG04OGEsAX-8jcYR&oh=7971d4578442ead66afee3656c50b400&oe=5ED5F3F0",
                    day: "22",
                    month: "Oct"
                },
                {
                    name: "Nikki Kristoffersen-Haley",
                    meta: "Senior Accountant • Team Echelon",
                    avatar: "https://randomuser.me/api/portraits/women/47.jpg",
                    day: "24",
                    month: "Oct"
                },
                {
                    name: "Yvonne Formentera",
                    meta: "QA Engineer • Team Halo",
                    avatar:
                            "https://pbs.twimg.com/profile_images/752461099807678464/DUhuoeI8.jpg",
                    day: "02",
                    month: "Nov"
                },
                {
                    name: "Colin Stark",
                    meta: "UI Designer • Team Halo",
                    avatar:
                            "https://scontent-frx5-1.cdninstagram.com/v/t51.2885-19/s320x320/16228890_594725164055676_7057958098689851392_a.jpg?_nc_ht=scontent-frx5-1.cdninstagram.com&_nc_ohc=FDvoG04OGEsAX-8jcYR&oh=7971d4578442ead66afee3656c50b400&oe=5ED5F3F0",
                    day: "22",
                    month: "Oct"
                },
                {
                    name: "Nikki Kristoffersen-Haley",
                    meta: "Senior Accountant • Team Echelon",
                    avatar: "https://randomuser.me/api/portraits/women/47.jpg",
                    day: "24",
                    month: "Oct"
                }
            ]
        };
    },
    mounted() {
        window.scrollTo(0, 0);
    }
};
</script>

<style lang="scss" scoped>
    header {
        h1 {
            margin-bottom: 0;
        }
        h2 {
            font-size: 1.5rem;
            font-weight: normal;
            color: var(--high-contrast, #{$dark-mid-grey});
        }
        p.desc {
            max-width: 500px;
        }
    }
    .user-row {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        padding: 0.5rem 0;
        border-top: 1px solid var(--low-contrast, #{$light-grey});
        &:first-of-type {
            padding-top: 0;
            border-top: 1px solid transparent;
        }
        .avatar {
            flex: 0 0 auto;
            margin-right: 0.75rem;
        }
        .text {
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
            width: 100px;
            .name,
            .meta {
                width: 100%;
                word-break: break-all;
                word-wrap: break-word;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .name {
                font-weight: bold;
            }
            .meta {
                font-size: 0.75rem;
            }
        }
    }
</style>
