<template>
    <section :is="tag">
        <h3 v-if="!hideTitle">
            {{ title }}
        </h3>
        <slot></slot>
    </section>
</template>

<script>
export default {
    name: "ProfileSection",
    props: {
        tag: {
            type: String,
            default: "section",
        },
        title: {
            type: String,
            default: "",
        },
        hideTitle: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>

</style>
