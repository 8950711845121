<template>
    <div class="pos-relative">
        <div
            ref="peopleMap"
            class="peopleMap"
        >
        </div>
        <div v-if="google && map">
            <marker-clusterer-component
                :map="map"
                :people="people"
            >
                <template #default="{cluster}">
                    <div>
                        <custom-marker-positioning-component
                            v-for="c in cluster"
                            :key="c.id"
                            :google="google"
                            :map="map"
                            :position="c.position"
                        >
                            <slot
                                :cluster-node="c"
                                :on-cluster-click="onClusterClick"
                            >
                            </slot>
                        </custom-marker-positioning-component>
                    </div>
                </template>
            </marker-clusterer-component>
        </div>
    </div>
</template>

<script>

import { Loader } from "@googlemaps/js-api-loader";
import { firebaseConfig } from "@web/firebaseConfig";
import AvatarList from "@web/components/AvatarList";
import CustomMarkerPositioningComponent from "@web/components/people-map/CustomMarkerPositioningComponent.vue";
import MarkerClustererComponent from "@web/components/people-map/MarkerClustererComponent";
import { locationEquals } from "@/services/UserSearchService";

export default {
    name: "PeopleMap",
    components: { MarkerClustererComponent, CustomMarkerPositioningComponent },
    props: {
        /**
         * ```javascript
         * [
         *   {
         *     uid: string,
         *     location: {
         *       name: string,
         *       detail: string,
         *       position: {
         *           lat: number,
         *           lng: number,
         *       }
         *     },
         *     photoURL?: string,
         *     displayName: string,
         *   },
         * ]
         * ```
         */
        people: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            google: undefined,
            map: undefined,
        };
    },
    mounted: async function() {
        console.debug("mounting map with people", this.people);
        await this.setupGoogleMaps();
        this.setViewPort({ people: this.people });
        console.debug("mounting map finished");
    },
    methods: {
        onClusterClick(cluster) {
            const peopleInCluster = cluster.people;
            cluster.everybodyAtSameLocation
                ? this.showDetailModal(peopleInCluster)
                : this.setViewPort(cluster);
        },
        async setupGoogleMaps() {
            const loader = new Loader({
                apiKey: firebaseConfig.apiKey,
                version: "3.47",
            });
            this.google = await loader.load();
            this.map = new this.google.maps.Map(this.$refs.peopleMap, {
                center: { lat: 1, lng: 1 },
                zoom: 1,
                clickableIcons: false,
                mapTypeControl: false,
                fullscreenControl: false,
                streetViewControl: false,
                styles: [
                    {
                        featureType: "poi",
                        stylers: [{ visibility: "off" }],
                    },
                    {
                        featureType: "road",
                        elementType: "geometry.fill",
                        stylers: [{ color: "#ffffff" }],
                    }, {
                        featureType: "road",
                        elementType: "geometry.stroke",
                        stylers: [{ color: "#ffffff" }],
                    }, {
                        featureType: "transit",
                        stylers: [{ visibility: "off" }],
                    },
                ],
            });
        },
        setViewPort(cluster) {
            const LatLngBounds = this.google.maps.LatLngBounds;

            const arePeopleAtSameLocation = cluster.everybodyAtSameLocation === undefined
                ? cluster.people.every(locationEquals)
                : cluster.everybodyAtSameLocation;

            // Don't zoom too far inside for only one person or only one cluster
            if (cluster.people.length === 1 || arePeopleAtSameLocation) {
                this.map.setCenter(cluster.people[0].location.position);
                this.map.setZoom(12);
                return;
            }

            const bounds = new LatLngBounds();
            for (const person of cluster.people) {
                bounds.extend(person.location.position);
            }

            this.map.fitBounds(bounds);
        },
        showDetailModal(people) {
            this.$modal.show("modal", {
                component: AvatarList,
                props: {
                    userUids: people.map(p => p.uid),
                    mode: "list",
                },
                modalProps: {
                    title: people[0].location.name,
                    subTitle: people[0].location.detail,
                    hideFooter: true,
                },
                events: {
                    clickOnUser: () => this.$modal.hide("modal"),
                },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.peopleMap {
    width: auto;
    aspect-ratio: 1.8;
    border-radius: var(--border-radius);
}

::v-deep {
    .cluster-icon.avatar {
        span {
            font-weight: $bold;
            font-size: $font-size-body;
        }
    }
}
</style>

<docs>
### Examples

```vue
<template>
    <GenericModal/>
</template>
<script>
    import GenericModal from "@/components/modals/GenericModal";

    export default {
        components: { GenericModal },
    };
</script>
```
#### Single Person
```vue
<template>
    <people-map :people='[{
        uid: "user uid",
        location: { position: { lat: 50.0782184, lng: 8.239760799999999, }, name: "Wiesbaden", detail: "Wiesbaden, Germany" },
        photoURL: "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1160&q=80",
    }]'>
        <template #default="{clusterNode, onClusterClick}">
            <avatar v-bind="clusterNode.people[0]"></avatar>
        </template>
    </people-map>
</template>
<script>
    import Avatar from "@web/components/Avatar";

    export default {
        components: { Avatar },
    };
</script>
```

#### Two people close to each other
```vue
<people-map :people='[{
        uid: "user uid",
        photoURL: "https://images.unsplash.com/photo-1561828970-daff2303fe58?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1160&q=80",
        location: { position: { lat: 50.0782184, lng: 8.239760799999999, }, name: "Bonifatiusplatz", detail: "Wiesbaden, Germany" },
        displayName: "Anna Nas"
    },{
        uid: "user uid 2",
        photoURL: "https://images.unsplash.com/photo-1595897952944-878f3abafb5a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1160&q=80",
        location: { position: { lat: 50.08, lng: 8.2397603, }, name: "Luisenplatz", detail: "Wiesbaden, Germany" },
        displayName: "Ute Beutel"
    }]'>
    <template #default="{clusterNode, onClusterClick}">
        <div class="cursor-pointer">
            <avatar
                v-if="clusterNode.people.length === 1"
                v-bind="clusterNode.people[0]"
            >
            </avatar>
            <avatar
                v-else
                @click.native="onClusterClick(clusterNode)"
            >
                {{ clusterNode.people.length }}
            </avatar>
        </div>
    </template>
</people-map>
```

#### People in different cities
```vue
<people-map :people='[{
        uid: "user uid",
        photoURL: "https://i.pravatar.cc/80?img=3",
        location: { position: { lat: 50.0782184, lng: 8.239760799999999}, name: "Wiesbaden", detail: "Wiesbaden, Germany" },
        displayName: "Anna Bolika"
    },{
        uid: "user uid 2",
        photoURL: "https://images.unsplash.com/photo-1536792414922-14b978901fcd?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1160&q=80",
        location: { position: { lat: 50.092184, lng: 8.269760799999999}, name: "Wiesbaden", detail: "Wiesbaden, Germany" },
        displayName: "Anna Bolika"
    },{
        uid: "user uid 3",
        photoURL: "https://images.unsplash.com/photo-1596935884412-2caade8438a8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1160&q=80",
        location: { position: { lat: 50.114253, lng: 8.681898 }, name: "Frankfurt", detail: "Frankfurt, Germany" },
        displayName: "Peter Silie"
    }]'>
    <template #default="{clusterNode, onClusterClick}">
        <div class="cursor-pointer">
            <avatar
                v-if="clusterNode.people.length === 1"
                v-bind="clusterNode.people[0]"
            >
            </avatar>
            <avatar
                v-else
                @click.native="onClusterClick(clusterNode)"
            >
                {{ clusterNode.people.length }}
            </avatar>
        </div>
    </template>
</people-map>
```

#### Worldwide company
```vue
<people-map :people='[{
        uid: "user uid",
        photoURL: "https://images.unsplash.com/photo-1536792414922-14b978901fcd?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1160&q=80",
        location: { position: { lat: 50.0782184, lng: 8.239760799999999}, name: "Wiesbaden", detail: "Wiesbaden, Germany" },
        displayName: "Paul Lahner"
    },{
        uid: "user uid 2",
        photoURL: "https://images.unsplash.com/photo-1581824043583-6904b080a19c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1138&q=80",
        location: { position: { lat: 37.7565817, lng: -122.4779014, }, name: "San Francisco", detail: "San Francisco, USA" },
        displayName: "Pia No"
    },{
        uid: "user uid 3",
        photoURL: "https://images.unsplash.com/photo-1485206412256-701ccc5b93ca?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1188&q=80",
        location: { position: { lat: -33.920664, lng: 18.417286, }, name: "Capetown", detail: "Capetown, South Africa" },
        displayName: "Lars Vegas"
    }]'>
    <template #default="{clusterNode, onClusterClick}">
        <div class="cursor-pointer">
            <avatar
                v-if="clusterNode.people.length === 1"
                v-bind="clusterNode.people[0]"
            >
            </avatar>
            <avatar
                v-else
                @click.native="onClusterClick(clusterNode)"
            >
                {{ clusterNode.people.length }}
            </avatar>
        </div>
    </template>
</people-map>
```

#### Overlapping persons
```vue
<people-map :people='[{
        uid: "user uid 1",
        photoURL: "https://images.unsplash.com/photo-1536792414922-14b978901fcd?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1160&q=80",
        location: { position: { lat: 50.0782184, lng: 8.239760799999999, }, name: "Wiesbaden", detail: "Wiesbaden, Germany" },
        displayName: "Wolfgang Schaltung"
    },{
        uid: "user uid 2",
        photoURL: "https://images.unsplash.com/photo-1596935884412-2caade8438a8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1160&q=80",
        location: { position: { lat: 50.0782184, lng: 8.239760799999999, }, name: "Wiesbaden", detail: "Wiesbaden, Germany" },
        displayName: "Theo Retisch"
    }]'>
    <template #default="{clusterNode, onClusterClick}">
        <div class="cursor-pointer">
            <avatar
                v-if="clusterNode.people.length === 1"
                v-bind="clusterNode.people[0]"
            >
            </avatar>
            <avatar
                v-else
                @click.native="onClusterClick(clusterNode)"
            >
                {{ clusterNode.people.length }}
            </avatar>
        </div>
    </template>
</people-map>
```
</docs>
