<template>
    <profile-section
        :tag="tag"
        :title="title"
        :hide-title="hideTitle"
        class="editable-section"
    >
        <div
            class="edit-button"
            :class="[buttonPosition]"
        >
            <Button
                v-if="editPermitted || isEditing"
                :type="isEditing? 'primary' : 'light'"
                class="circle"
                :class="{
                    save: isEditing,
                    busy: isSaving,
                }"
                :disabled="isEditing && !isValid"
                :busy="isSaving"
                :icon="isEditing ? 'check' : 'edit_2'"
                :aria-label="isEditing ? $t('save_element', [title]) : $t('edit_element', [title])"
                @click="toggleEditMode"
            />
        </div>
        <slot
            v-if="!isEditing"
            name="viewing"
        ></slot>
        <slot
            v-if="isEditing"
            name="editing"
            v-bind="{formData, isSaving, setValidationState}"
        ></slot>
    </profile-section>
</template>

<script>
import { analytics } from "@web/analytics";
import { getGlobalConfiguration } from "@web/global-config";
import {
    mapActions,
    mapGetters,
} from "vuex";
import {
    INTRANET,
    IS_ADMIN
} from "@web/store/intranet/getters";
import { AUTH_MODULE_NAME } from "@web/store/auth/auth";
import { CURRENT_USER } from "@web/store/auth/getters";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import { TEAM_MODULE_NAME } from "@/store/team/team";
import {
    CREATE_TEAM,
    UPDATE_TEAM,
} from "@web/store/team/actions";
import Button from "@web/components/Button";
import ProfileSection from "@web/views/intranet/directory/ProfileDetail/ProfileSection";
import {
    formDataToTeamProfile,
    teamProfileToFormData,
} from "@/lib/profile-form-conversion";

const ButtonPosition = {
    topRight: "top-right",
    bottomRight: "bottom-right",
};

/**
 * When adding a profile field also see {@link userProfileToFormData} and {@link formDataToUserProfile}.
 */
export default {
    name: "EditableTeamSection",
    components: {
        ProfileSection,
        Button,
    },
    props: {
        ...ProfileSection.props,
        /** @type {TeamProfile} */
        team: { type: Object, default: () => ({}) },
        /** @type {Array<keyof TeamProfileUpdatePayload>} */
        fields: { type: Array, default: () => [] },
        editable: Boolean,
        /** @values top-right, bottom-right */
        buttonPosition: { type: String, default: ButtonPosition.topRight, validator: (value) => Object.values(ButtonPosition).includes(value) },
        openInEditMode: Boolean,
        title: { type: String, default: "" },
    },
    data() {
        return {
            isEditing: false,
            isSaving: false,
            isValid: true,
            formData: undefined,
        };
    },
    computed: {
        ...mapGetters({
            intranet: INTRANET_MODULE_NAME + INTRANET,
            currentUser: AUTH_MODULE_NAME + CURRENT_USER,
            isAdmin: INTRANET_MODULE_NAME + IS_ADMIN,
        }),
        editPermitted() {
            if (!this.editable) return false;
            return (this.team.members.find((member) => member.uid === this.currentUser.uid) || this.isAdmin);
        },
    },
    watch: {
        isEditing(isEditing) {
            this.$emit("update:editing", isEditing);
        },
    },
    mounted() {
        if (this.openInEditMode) {
            this.toggleEditMode();
        }
    },
    methods: {
        ...mapActions({
            createTeam: TEAM_MODULE_NAME + CREATE_TEAM,
            updateTeam: TEAM_MODULE_NAME + UPDATE_TEAM,
        }),
        toggleEditMode() {
            if (this.isEditing) {
                this.saveProfileSection();
            } else if (this.editPermitted) {
                this.formData = teamProfileToFormData(this.team, this.fields);
                this.isEditing = true;
            }
        },
        setValidationState(isValid) {
            this.isValid = isValid;
        },
        async saveProfileSection() {
            this.isSaving = true;
            const profileData = formDataToTeamProfile(this.formData, this.fields);
            try {
                if (profileData !== {}) {
                    await this.updateTeam({ teamUid: this.team.uid, teamUpdatePayload: profileData });
                    analytics.log(getGlobalConfiguration().analytics_event_name_teams_profile_edit, { fields: Object.keys(profileData) });
                    this.isEditing = false;
                    this.$emit("section-updated", profileData);
                }
            } catch (error) {
                const text = error.response.data.map(({ message }) => message).join("<br/>");
                this.$notify({
                    group: "app",
                    type: "error",
                    text,
                });
            } finally {
                this.isSaving = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.editable-section {
    position: relative;
}

.edit-button {
    position: absolute;
    z-index: 1;

    &.top-right {
        top: 0;
        right: 0;
    }

    &.bottom-right {
        bottom: 0;
        right: 0;
    }

    ::v-deep {
        .button.circle {
            padding: 0.5rem;
        }
    }
}
</style>
