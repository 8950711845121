import { LatLngLiteral } from "@backend/user-profile/types";

let markerOverlay: any = null;

// We declare the class lazy, because the Google object is not available early enough.
export function provideMarkerOverlay(google: any): any {
    if (markerOverlay !== null) return markerOverlay;
    markerOverlay = class MarkerOverlay extends google.maps.OverlayView {
        el: HTMLElement;
        position: LatLngLiteral;

        constructor(el: HTMLElement, position: LatLngLiteral) {
            super();
            this.el = el;
            this.position = position;
        }

        repaint() {
            const projection = this.getProjection();
            if (!projection) return;

            const { lat, lng } = this.position;
            const latLng = new google.maps.LatLng(lat, lng);
            const posPixel = projection.fromLatLngToDivPixel(latLng);
            if (!posPixel) return;
            const x = posPixel.x - this.el.offsetWidth / 2;
            const y = posPixel.y - this.el.offsetHeight / 2;
            this.el.style.left = x + "px";
            this.el.style.top = y + "px";
        }

        draw() {
            this.repaint();
        }

        setPosition() {
            this.repaint();
        }

        getDraggable() {
            return false;
        }

        getPosition() {
            const { lat, lng } = this.position;
            return new google.maps.LatLng(lat, lng);
        }

        onAdd() {
            console.debug("adding custom marker");
            const panes = this.getPanes();
            if (!this.el || !panes) return;
            panes.overlayLayer.appendChild(this.el);
            panes.overlayMouseTarget.appendChild(this.el);
        }

        onRemove() {
            console.debug("removing custom marker");
            const ua = navigator.userAgent;
            const msie = ua.indexOf("MSIE ");
            if (msie > 0 || !!ua.match(/Trident.*rv:11\./)) {
                this.el.parentNode!.removeChild(this.el);
            } else {
                this.el.remove();
            }
        }
    };

    return markerOverlay;
}
