<template>
    <div class="container">
        <SystemMessage
            v-if="error"
            :type="error.type"
        />
        <section v-else>
            <div class="mtop-medium">
                <div
                    ref="cover"
                    class="cover-image background-lowest-contrast border-radius-medium"
                    :style="coverStyling"
                >
                    <avatar
                        class="team-avatar"
                        v-bind="{photoUrl: team.profileImagePath, displayName: team.name}"
                        icon="users"
                        size="xxlarge"
                    />
                    <div class="name">
                        <div>{{ $t("team_profile_title") }}</div>
                        <h1 class="ellipsis-multiline">
                            {{ team.name }}
                        </h1>
                    </div>
                </div>
                <TeamDescription
                    :team="team"
                    :editable="userAllowedToEdit"
                    class="team-description"
                    @section-updated="onSectionUpdated"
                />
            </div>
        </section>
        <hr class="divider"/>
        <section
            v-if="team.members && team.members.length > 0"
            class="mtop-medium"
        >
            <div class="grid">
                <div
                    v-if="peopleOnMap.length"
                    class="col half"
                >
                    <h3>{{ $t("team_profile_map_title") }}</h3>
                    <pu-skeleton
                        :loading="usersLoading"
                        height="20rem"
                    >
                        <people-map
                            :people="peopleOnMap"
                        >
                            <template #default="{clusterNode, onClusterClick}">
                                <people-map-marker
                                    :cluster-node="clusterNode"
                                    @avatar-click="onMapClick('avatar')"
                                    @cluster-click="onClusterClick(clusterNode); onMapClick('cluster')"
                                />
                            </template>
                        </people-map>
                    </pu-skeleton>
                </div>
                <div class="col half">
                    <h3>
                        {{ $t("team_profile_member_list_title") }}
                        <span
                            class="normal-weight text-high-contrast"
                        >{{ team.members.length }}
                        </span>
                    </h3>
                    <pu-skeleton
                        :loading="usersLoading"
                        height="20rem"
                    >
                        <member-row
                            v-for="user in team.members"
                            :key="user.uid"
                            :user="user"
                        />
                    </pu-skeleton>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { INTRANET_MODULE_NAME } from "@/store/intranet/intranet";
import {
    INTRANET_UID,
    IS_ADMIN,
} from "@/store/intranet/getters";
import Avatar from "@/components/Avatar";
import {
    teamService,
    userService,
} from "@/services";
import MemberRow from "@/views/intranet/directory/teams/MemberRow";
import TeamDescription from "@/views/intranet/directory/teams/TeamProfile/TeamDescription";
import { AUTH_MODULE_NAME } from "@/store/auth/auth";
import { CURRENT_USER } from "@/store/auth/getters";
import PeopleMap from "@/components/people-map/PeopleMap";
import { analytics } from "@/analytics";
import { getGlobalConfiguration } from "@/global-config";
import PeopleMapMarker from "@/components/people-map/PeopleMapMarker";
import { DeltaFormat } from "@backend/entity/types";

export default {
    name: "TeamProfile",
    components: {
        PeopleMapMarker,
        PeopleMap,
        TeamDescription,
        MemberRow,
        Avatar,
    },
    props: {
        teamUid: { type: String, required: true },
    },
    data() {
        return {
            usersLoading: true,
            error: undefined,
            team: {
                members: [],
                name: "",
                profileImagePath: undefined,
                profileCoverImagePath: "/team-bg.jpg",
                description: undefined,
            },
        };
    },
    computed: {
        ...mapGetters({
            intranetUid: INTRANET_MODULE_NAME + INTRANET_UID,
            currentUser: AUTH_MODULE_NAME + CURRENT_USER,
            isAdmin: INTRANET_MODULE_NAME + IS_ADMIN,
        }),
        userAllowedToEdit() {
            return this.team.members.some(member => member.uid === this.currentUser.uid) || this.isAdmin;
        },
        coverStyling() {
            return {
                "background-image": `linear-gradient(transparent 50%, rgba(0,0,0,0.5) 100%), url(${this.team.profileCoverImagePath})`,
            };
        },
        peopleOnMap() {
            return this.team
                ? this.team.members.filter(member => member.location && member.location.position).map(member => ({
                    uid: member.uid,
                    location: member.location,
                    photoURL: member.photoURL,
                    displayName: member.displayName,
                }))
                : [];
        },
    },
    async mounted() {
        const teamProfilePromise = teamService.getTeam(this.intranetUid, this.teamUid, DeltaFormat.web).then((team) => {
            this.team = { ...this.team, ...team };
        });

        const teamMemberPromise = userService.search.searchTeamMembers({ intranetUid: this.intranetUid, teamUid: this.teamUid })
            .then((searchResponse) => {
                this.team.members = searchResponse.hits;
            });

        Promise.all([teamProfilePromise, teamMemberPromise])
            .catch((e) => {
                if (e.response.status === 404) {
                    this.error = {
                        type: "404",
                    };
                    return;
                }
                this.error = {
                    type: "unknown",
                };
            })
            .finally(() => {
                this.usersLoading = false;
            });
    },
    methods: {
        onSectionUpdated(data) {
            this.team = { ...this.team, ...data };
        },
        onMapClick(type) {
            if (type === "avatar") {
                analytics.log(getGlobalConfiguration().analytics_event_name_teams_profile_map_avatar_clicked, { teamUid: this.teamUid });
            } else if (type === "cluster") {
                analytics.log(getGlobalConfiguration().analytics_event_name_teams_profile_map_cluster_clicked, { teamUid: this.teamUid });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.normal-weight {
    font-weight: normal;
}

.cover-image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 300px;
    width: 100%;
    max-width: 100%;
    aspect-ratio: 3.3/1;
    display: flex;
    align-items: flex-end;
}

.team-avatar {
    position: absolute;
    transform: translateY(50%);
    flex: 0 0 auto;
    margin: 1.5rem;
}

.name {
    color: white;
    margin-left: calc(196px + 3.5rem); // left side: avatar-xxlarge width + margin
    flex: 1 1 auto;
    overflow: hidden;
}

.team-description {
    margin-top: 1rem;
    max-width: 42rem;
    padding-right: 3rem;
    margin-left: calc(196px + 3.5rem);
    width: calc(100% - 4rem - 196px);
}

@media screen and (max-width: $breakpoint-profile-large) {
    .team-avatar {
        transform: translateY(0%);
    }
    .team-description {
        width: 100%;
        padding-left: 0;
        margin-left: 0;
    }

}

@media screen and (max-width: $breakpoint-profile-small) {
    .team-avatar {
        height: 128px;
        width: 128px;
        margin-bottom: 1.5rem;
    }

    .name {
        margin-left: calc(128px + 2.5rem);
    }
}

.container {
    padding-bottom: 10rem;
}

.member-list-title {
    align-items: center;

    h3 {
        margin-bottom: 0;
        margin-right: 0.5rem;
    }

    p {
        margin-bottom: 0;
        margin-top: 0.2rem;
    }
}
</style>
