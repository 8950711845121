import Supercluster from "supercluster";
import { locationEquals } from "@/services/UserSearchService";

export default {
    props: {
        /**
         * ```javascript
         * [
         *   {
         *     uid: string,
         *     location: {
         *       name: string,
         *       detail: string,
         *       position: {
         *           lat: number,
         *           lng: number,
         *       }
         *     },
         *     photoURL?: string,
         *     displayName: string,
         *   },
         * ]
         * ```
         */
        people: {
            type: Array,
            required: true,
        },
        map: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            cluster: [],
            supercluster: undefined,
        };
    },
    mounted() {
        this.initCluster();
        this.setupListeners();
    },
    methods: {
        getCluster() {
            return this.supercluster.getClusters([-180, -90, 180, 90], Math.round(this.map.zoom))
                .map(this.mapSuperClusterResult);
        },
        initCluster() {
            this.supercluster = new Supercluster({
                radius: 60,
                maxZoom: 30,
            });
            const clusterPoints = this.people.map(person => ({
                type: "Feature",
                geometry: {
                    type: "Point",
                    coordinates: [person.location.position.lat, person.location.position.lng],
                },
                properties: { person },
            }));
            this.supercluster.load(clusterPoints);
        },
        mapSuperClusterResult({ geometry: { coordinates: [lat, lng] }, properties }) {
            if (!properties.cluster) {
                const person = properties.person;
                return {
                    id: person.uid,
                    people: [person],
                    position: person.location.position,
                };
            }

            const people = this.supercluster.getLeaves(properties.cluster_id, Infinity).map(leaf => leaf.properties.person);
            const everybodyAtSameLocation = people.every(locationEquals);

            return {
                id: people.map(p => p.uid).sort().join(),
                people,
                position: { lat, lng },
                everybodyAtSameLocation,
            };
        },
        setupListeners() {
            this.map.addListener("zoom_changed", () => {
                console.debug("zoom changed – setting new clusters");
                this.cluster = [];
                this.$nextTick(() => {
                    this.cluster = this.getCluster();
                    console.debug("new clusters", this.cluster);
                });
            });
        },
    },
    render() {
        return this.$scopedSlots.default({
            cluster: this.cluster,
        });
    },
};
