<template>
    <div
        v-if="user"
        class="user-row"
    >
        <user-link
            :uid="user.uid"
            class="avatar avatar-medium mright-small"
        >
            <img
                :src="user.photoURL"
                alt=""
            />
        </user-link>

        <div class="display-name">
            <div class="flex flex-center">
                <user-link :uid="user.uid">
                    {{ user.displayName }}
                </user-link>
                <transition name="fade">
                    <span
                        v-if="hasAdminPermissions"
                        class="pill light mleft-xsmall"
                    >Admin</span>
                </transition>
            </div>
            <span class="label">{{ user.jobTitle }}</span>
        </div>

        <div class="meta email">
            <a
                :href="`mailto:${user.email}`"
                target="_blank"
                rel="noopener noreferrer"
            >
                <icon
                    name="mail"
                    color="var(--primary)"
                />
            </a>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import Icon from "@web/components/Icon.vue";
import { mapGetters } from "vuex";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import { INTRANET_UID } from "@web/store/intranet/getters";
import UserLink from "@/components/user/UserLink";

export default Vue.extend({
    name: "MemberRow",
    components: { Icon, UserLink },
    props: {
        user: {
            type: Object,
        }
    },
    data() {
        return {
            startDeleting: false,
        };
    },
    computed: {
        ...mapGetters({
            intranetUid: INTRANET_MODULE_NAME + INTRANET_UID,
        }),
        hasAdminPermissions() {
            return this.user.roles && this.user.roles.includes("admin");
        },
    },
});
</script>

<style lang="scss" scoped>
.user-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    max-width: 30rem;
    justify-content: space-between;
    align-items: center;

    border-bottom: 1px solid rgba(black, 0.1);
    padding: 10px 0;

    &:hover {
        background-color: var(--lowest-contrast);
    }

    &.start-deleting > * {
        opacity: 0.2;
    }
    .avatar {
        flex: 0 0 auto;
    }
    .display-name {
        margin: 0 20px 0 0;
        width: 200px;
        flex: 4 0 auto;
    }
    .email {
        margin: 0 20px 0 0;
    }

    .join-date {
        width: 100px;
        margin: 0 20px 0 0;
        flex: 3 0 auto;
    }

    .meta a {
        text-decoration: none;
        vertical-align: text-bottom;
    }
}
</style>
