<template>
    <div class="pos-absolute d-inline-block">
        <slot></slot>
    </div>
</template>

<script>
import { provideMarkerOverlay } from "@web/components/people-map/MarkerOverlay";

export default {
    props: {
        /**
         * Google maps object
         */
        map: {
            type: Object,
            required: true,
        },
        /**
         * Google object
         */
        google: {
            type: Object,
            required: true,
        },
        /**
         * {
         *     lat: number,
         *     lng: number
         * }
         */
        position: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            overlay: undefined,
        };
    },
    watch: {
        position: {
            deep: true,
            handler() {
                this.overlay.setPosition();
            },
        },
    },
    mounted() {
        console.debug("mounting custom marker");
        const MarkerOverlay = provideMarkerOverlay(this.google);
        this.overlay = new MarkerOverlay(this.$el, this.position);
        this.overlay.setMap(this.map);
    },
    methods: {
        destroyed() {
            console.debug("destroying custom marker");
            if (this.overlay) {
                this.overlay.setMap(null);
                this.overlay.repaint();
                this.overlay = undefined;
            }
        },
    },
};

</script>
