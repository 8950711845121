import { render, staticRenderFns } from "./EditableTeamSection.vue?vue&type=template&id=6b81d66d&scoped=true&"
import script from "./EditableTeamSection.vue?vue&type=script&lang=js&"
export * from "./EditableTeamSection.vue?vue&type=script&lang=js&"
import style0 from "./EditableTeamSection.vue?vue&type=style&index=0&id=6b81d66d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b81d66d",
  null
  
)

export default component.exports