import {
    dateToDateYMD,
    dateYMDToDate
} from "@web/lib/time-utils";
import {
    DateYMD,
    Phone,
    UserProfile,
    UserProfileUpdatePayload
} from "@backend/user-profile/types";
import {
    TeamProfileUpdatePayload,
    TeamProfile,
} from "@backend/team/types";

type UserProfileFields = Array<keyof UserProfileUpdatePayload>;
type ProfileFormData = Partial<{ [key in keyof UserProfileUpdatePayload]?: unknown }>;

// Fields of UserProfile with value type DateYMD
const dateYMDFields: Array<keyof UserProfile> = ["birthday", "hiringDate"];

// Fields of UserProfile with value type location
const locationFields: Array<keyof UserProfile> = ["location"];

// Fields of UserProfile with value type Phone
const phoneFields: Array<keyof UserProfile> = ["phone"];

export function userProfileToFormData(userProfile: Partial<UserProfile>, fields: UserProfileFields): ProfileFormData {
    const formData: ProfileFormData = {};
    for (const key of fields) {
        if (dateYMDFields.includes(key)) {
            formData[key] = dateYMDToDate(userProfile[key] as DateYMD);
        } else if (phoneFields.includes(key)) {
            formData[key] = userProfile[key] ?? {
                number: "",
            };
        } else {
            formData[key] = userProfile[key];
        }
    }
    return formData;
}

export function formDataToUserProfile(formData: ProfileFormData, fields: UserProfileFields): UserProfileUpdatePayload {
    const profileData: UserProfileUpdatePayload = {};
    for (const key of fields) {
        const value = formData[key];
        const isEmptyPhoneField = phoneFields.includes(key) && (value as Phone).number === "";
        if (!value || isEmptyPhoneField) {
            // with null value the backend deletes that field.
            // Tested as falsy, also empty strings and 0 are considered to be deleted.
            profileData[key] = null;
        } else {
            if (dateYMDFields.includes(key)) {
                // @ts-ignore left type is always DateYMD
                profileData[key] = dateToDateYMD(value as Date);
            } else if (locationFields.includes(key)) {
                // @ts-ignore
                if (value.name === "" && !value.place_id) {
                    // @ts-ignore
                    profileData[key] = null;
                } else {
                    // @ts-ignore
                    profileData[key] = value;
                }
            } else {
                // @ts-ignore left type is always not DateYMD
                profileData[key] = value;
            }
        }
    }
    return profileData;
}

type TeamProfileFields = Array<keyof TeamProfileUpdatePayload>;
type TeamProfileFormData = Partial<{ [key in keyof TeamProfileUpdatePayload]?: unknown }>;

export function teamProfileToFormData(teamProfile: Partial<TeamProfile>, fields: TeamProfileFields): TeamProfileFormData {
    const formData: TeamProfileFormData = {};
    for (const key of fields) {
        formData[key] = teamProfile[key];
    }
    return formData;
}

export function formDataToTeamProfile(formData: TeamProfileFormData, fields: TeamProfileFields): TeamProfileUpdatePayload {
    const profileData: TeamProfileUpdatePayload = {};
    for (const key of fields) {
        // @ts-ignore left type is always not DateYMD
        profileData[key] = formData[key];
    }
    return profileData;
}
